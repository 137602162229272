import '../list.css'
import glassware1 from '../../../../assets/glassware/GlasswareAndBarware.webp'
import glassware2 from '../../../../assets/glassware/bar.jpg'
import glassware3 from '../../../../assets/glassware/barware.jpg'
import glassware4 from '../../../../assets/glassware/glassware.jpg'
import glassware5 from '../../../../assets/glassware/GlasswareAndBarware.webp'
import glassware6 from '../../../../assets/glassware/bar.jpg'
import glassware7 from '../../../../assets/glassware/barware.jpg'
import glassware8 from '../../../../assets/glassware/glassware.jpg'

const Glassware = () => {
  return (
    <div className='category-list'>
      <h2 className='category-list-h2'>
        Sample of Glassware & Barware items here...
      </h2>
      <div className='list'>
        <img className='list-img' src={glassware1} alt='' />
        <img className='list-img' src={glassware2} alt='' />
        <img className='list-img' src={glassware3} alt='' />
        <img className='list-img' src={glassware4} alt='' />
        <img className='list-img' src={glassware5} alt='' />
        <img className='list-img' src={glassware6} alt='' />
        <img className='list-img' src={glassware7} alt='' />
        <img className='list-img' src={glassware8} alt='' />
      </div>
    </div>
  )
}

export default Glassware
