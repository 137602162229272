import '../list.css'
import cutlery1 from '../../../../assets/cutlery/cutlery.jpg'
import cutlery2 from '../../../../assets/cutlery/cutlery1.webp'
import cutlery3 from '../../../../assets/cutlery/cutlery2.jpg'
import cutlery4 from '../../../../assets/cutlery/cutlery.jpg'
import cutlery5 from '../../../../assets/cutlery/cutlery1.webp'
import cutlery6 from '../../../../assets/cutlery/cutlery2.jpg'
import cutlery7 from '../../../../assets/cutlery/cutlery.jpg'
import cutlery8 from '../../../../assets/cutlery/cutlery1.webp'

const Cutlery = () => {
  return (
    <div className='category-list'>
      <h2 className='category-list-h2'>Sample of Cutlery items here...</h2>
      <div className='list'>
        <img className='list-img' src={cutlery1} alt='' />
        <img className='list-img' src={cutlery2} alt='' />
        <img className='list-img' src={cutlery3} alt='' />
        <img className='list-img' src={cutlery4} alt='' />
        <img className='list-img' src={cutlery5} alt='' />
        <img className='list-img' src={cutlery6} alt='' />
        <img className='list-img' src={cutlery7} alt='' />
        <img className='list-img' src={cutlery8} alt='' />
      </div>
    </div>
  )
}

export default Cutlery
