import '../list.css'
import kitchen1 from '../../../../assets/kitchen/KitchenEquipment.jpg'
import kitchen2 from '../../../../assets/kitchen/SmallwareEquipment.jpg'
import kitchen3 from '../../../../assets/kitchen/kitchenequip.jpg'
import kitchen4 from '../../../../assets/kitchen/kitchenequip2.jpg'
import kitchen5 from '../../../../assets/kitchen/KitchenEquipment.jpg'
import kitchen6 from '../../../../assets/kitchen/SmallwareEquipment.jpg'
import kitchen7 from '../../../../assets/kitchen/kitchenequip.jpg'
import kitchen8 from '../../../../assets/kitchen/kitchenequip2.jpg'

const Kitchen = () => {
  return (
    <div className='category-list'>
      <h2 className='category-list-h2'>
        Sample of Kitchen Equipment items here...
      </h2>
      <div className='list'>
        <img className='list-img' src={kitchen1} alt='' />
        <img className='list-img' src={kitchen2} alt='' />
        <img className='list-img' src={kitchen3} alt='' />
        <img className='list-img' src={kitchen4} alt='' />
        <img className='list-img' src={kitchen5} alt='' />
        <img className='list-img' src={kitchen6} alt='' />
        <img className='list-img' src={kitchen7} alt='' />
        <img className='list-img' src={kitchen8} alt='' />
      </div>
    </div>
  )
}

export default Kitchen
