import './footer.css';
import { Link, useNavigate } from 'react-router-dom';
const Footer = () => {

  const navigate= useNavigate()
  const handleNewsletter=()=>{
    navigate('/newsletter');
  }
  return (
    <div className='footer'>
      <div className='footer-hr'>
        <hr />
      </div>
      <div className='footer-container'>
        <div className='footer-list-social'>
          <ul className='footer-ul'>
            <li className='footer-li'><Link style={{textDecoration:"none"}} to='/about'>ABOUT US</Link></li>
            <li className='footer-li'><Link style={{textDecoration:"none"}} to='/about'>CAREER</Link></li>
            <li className='footer-li'><Link style={{textDecoration:"none"}} to='/about'>BECOME A SELLER</Link></li>
            <li className='footer-li'><Link style={{textDecoration:"none"}} to='/about'>FAQ</Link></li>
            <li className='footer-li'><Link style={{textDecoration:"none"}} to='/about'>PRIVACY POLICY</Link></li>
            <li className='footer-li'><Link style={{textDecoration:"none"}} to='/about'>TERMS & CONDITIONS</Link></li>
          </ul>
          <div className='footer-social'>
            <a href="https://www.facebook.com"><img
              className='footer-social-img'
              src='https://static.wixstatic.com/media/e316f544f9094143b9eac01f1f19e697.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e316f544f9094143b9eac01f1f19e697.png'
              alt=''
            /></a>
            <a href="https://www.twitter.com"><img
              className='footer-social-img'
              src='https://static.wixstatic.com/media/9c4b521dd2404cd5a05ed6115f3a0dc8.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9c4b521dd2404cd5a05ed6115f3a0dc8.png'
              alt=''
            /></a>
            <a href="https://www.instagram.com"><img
              className='footer-social-img'
              src='https://static.wixstatic.com/media/8d6893330740455c96d218258a458aa4.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/8d6893330740455c96d218258a458aa4.png'
              alt=''
            /></a>
            <a href="https://www.linkedin.com"><img
              className='footer-social-img'
              src='https://static.wixstatic.com/media/48a2a42b19814efaa824450f23e8a253.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/48a2a42b19814efaa824450f23e8a253.png'
              alt=''
            /></a>
          </div>
        </div>
        <div className='footer-contact'>
          <div className='footer-contact1'>
            <span className='footer-span1'>SUPPORT</span>
            <img
              className='footer-contact1-img'
              src='https://img.icons8.com/?size=100&id=ZxJ4etQxTELs&format=png&color=FFFFFF'
              alt=''
            />
          </div>
          <p className='footer-p1'>
            Contact <span className='footer-span2'>With Us..</span>
          </p>
          <form action='' className='footer-form'>
            <div className='footer-form1'>
              <input type='text' name='name' placeholder='Name *' required />
              <input type='email' name='email' placeholder='Email *' required />
            </div>
            <input
              type='phone'
              name='phone'
              placeholder='Phone No. *'
              required
            />
            <textarea
              name='message'
              id='message'
              placeholder='Message'
            ></textarea>
            <label htmlFor='business'>Business Category *</label>
            <select id='business' name='business' required>
              <option value='resturant'>Resturant</option>
              <option value='hotel'>Hotel</option>
              <option value='catering'>Catering</option>
              <option value='reseller'>Reseller</option>
            </select>
            <div className='footer-form2'>
              <input
                type='checkbox'
                id='subscribe'
                name='newsletter'
                value='newsletter'
                required
              />
              <label htmlFor='subscribe'>
                I want to subscribe to the <a onClick={handleNewsletter} href="/newsletter">newsletter.</a>.
              </label>
            </div>
            <button className='footer-btn'>Send</button>
          </form>
        </div>
        <div className='footer-contact-area'>
          <div className='footer-logo'>
            <div className='footer-logo-heading'>
              <h3 className='footer-logo-h3'>Essify</h3>
              <img
                className='footer-logo-img'
                src='https://img.icons8.com/?size=100&id=78599&format=png&color=F5CF29'
                alt='logo'
              />
            </div>
            <h2 className='footer-h2'>
            Mohammad Imran, Rajiv Nagar, Maharajganj, Uttar Pradesh, 273303 & Availability of service in Bharat
            </h2> 
          </div>
          <p className='footer-p2'>
            We have client Presence in all the states in India and we can also
            serve customers all over Asia and rest of the world. Here are few
            places where we have served and supplied products to our clients in
            Mumbai, Delhi, Bangalore, Hyderabad, Ahmedabad, Chennai, Kolkata,
            Surat, Pune, Jaipur, Kanpur, Nagpur, Lucknow, Visakhapatnam, Thane,
            Bhopal, Indore, Patna, Vadodara, Ghaziabad, Ludhiana, Agra, Nashik,
            Faridabad, Meerut, Rajkot, Varanasi, Srinagar, Aurangabad, Dhanbad,
            Amritsar, Navi Mumbai, Allahabad, Howrah, Ranchi, Gwalior, Jabalpur,
            Coimbatore, Vijayawada, Jodhpur, Madurai, Raipur, Chandigarh,
            Guwahati, Solapur, Hubli-Dharwad, Mysore, Tiruchirappalli, Bareilly,
            Aligarh, Tiruppur, Gurgaon, Moradabad, Jalandhar, Bhubaneswar,
            Salem, Warangal, Mira-Bhayandar, Jalgaon, Kota, Guntur, Bhiwandi,
            Saharanpur, Gorakhpur, Bikaner, Amravati, Noida, Jamshedpur, Bhilai,
            Cuttack, Firozabad, Kochi, Nellore, Bhavnagar, Dehradun, Durgapur,
            Asansol, Rourkela, Nanded, Kolhapur, Ajmer, Akola, Gulbarga,
            Jamnagar, Ujjain, Loni, Siliguri, Jhansi, Ulhasnagar, Jammu, Sangli,
            Kupwad,, Mangalore, Erode, Belgaum, Ambattur, Tirunelveli, Malegaon,
            Gaya, Thiruvananthapuram, Udaipur, Kakinada, Davanagere, Kozhikode,
            Maheshtala, Rajpur Sonarpur, Rajahmundry, Bokaro, South Dumdum,
            Bellary, Patiala, Gopalpur, Agartala, Bhagalpur, Muzaffarnagar,
            Bhatpara, Panihati, Latur, Dhule, Tirupati, Rohtak, Sagar, Korba,
            Bhilwara, Berhampur, Muzaffarpur, Ahmednagar, Mathura, Kollam,
            Avadi, Kadapa, Kamarhati, Sambalpur, Bilaspur, Shahjahanpur, Satara,
            Bijapur, Kurnool, Rampur, Shimoga, Chandrapur, Junagadh, Thrissur,
            Alwar, Bardhaman, Kulti, Nizamabad, Parbhani, Tumkur, Khammam,
            Ozhukarai, Panipat, Darbhanga, Bally, Aizawl, Dewas, Ichalkaranji,
            Karnal, Bathinda, Jalna, Eluru, Barasat, Kirari Suleman Nagar,
            Purnia, Satna, Mau, Sonipat, Farrukhabad, Durg, Imphal, Ratlam,
            Hapur, Arrah, Anantapur, Karimnagar, Etawah, Ambarnath, North
            Dumdum, Bharatpur, Begusarai, New Delhi, Gandhidham, Baranagar,
            Tiruvottiyur, Pondicherry, Sikar, Thoothukudi, Rewa, Mirzapur,
            Raichur, Pali, Ramagundam, Haridwar, Vijayanagaram, Tenali,
            Nagercoil, Sri Ganganagar, Karawal Nagar, Mango, Thanjavur,
            Bulandshahr, Uluberia, Katni, Sambhal, Singrauli, Nadiad,
            Secunderabad, Naihati, Yamunanagar, Bidhannagar, Pallavaram, Bidar,
            Munger, Panchkula, Burhanpur, Kharagpur, Dindigul, Gandhinagar,
            Hospet, Nangloi Jat, Malda, Ongole, Deoghar, Chapra, Haldia,
            Khandwa, Nandyal, Morena, Amroha, Anand, Bhind, Bhalswa Jahangir
            Pur, Madhyamgram, Bhiwani, Berhampore, Ambala, Morbi, Fatehpur,
            Raebareli, Mahaboobnagar, Chittoor, Bhusawal, Orai, Bahraich,
            Vellore, Mehsana, Raiganj, Sirsa, Danapur, Serampore, Guna, Jaunpur,
            Panvel, Shivpuri, Unnao, Chinsurah, Alappuzha, Kottayam,
            Machilipatnam, Shimla, Adoni, Udupi, Katihar, Proddatur, Saharsa,
            Hindupur, Sasaram, Hajipur, Bhimavaram, Kumbakonam, Dehri,
            Madanapalle, Siwan, Bettiah, Guntakal, Srikakulam, Motihari,
            Dharmavaram, Gudivada, Phagwara, Narasaraopet, Suryapet,
            Miryalaguda, Tadipatri, Karaikudi, Kishanganj, Jamalpur, Ballia,
            Kavali, Tadepalligudem, Amaravati, Buxar, Jehanabad, Gangtok,
            Maharashtra, Karnataka, Telangana, Gujarat, Tamil Nadu, West Bengal,
            Rajasthan, Uttar Pradesh, Andhra Pradesh, Madhya Pradesh, Bihar,
            Punjab, Haryana, Jharkhand, Uttar Pradesh, Chhattisgarh, Assam,
            Odisha, Kerala, Uttarakhand, Tripura, Puducherry, Mizoram, Manipur,
            Himachal Pradesh, Sikkim, Andaman And Nicobar, Laddakh, Leh,
            Kashmir, Puducherry, Daman, Silvasa. We can also serve abroad in the
            USA, Malaysia, Philippines, Indonesia, Singapore, UK, Canada, Nepal,
            Bhutan, and many more. We deal in Crockery, Cutlery, Glasswares,
            Kitchen equipment, Packaging material, Barware, Tableware,
            Smallwares, Knives, uniforms, Room amenities, Furniture, Fixtures,
            OS&E, Bed, Linen, Housekeeping Material, Trolleys, Cleaning
            equipments for the Restaurant, Hotels and Catering
          </p>
        </div>
      </div>
      <p className='footer-copyright'>Copyright© 2024. All rights reserved.</p>
    </div>
  )
}

export default Footer
