import '../list.css'
import amenities1 from '../../../../assets/roomAmenities/HotelRoomAmenities.webp'
import amenities2 from '../../../../assets/roomAmenities/RoomAmenities.jpg'
import amenities3 from '../../../../assets/roomAmenities/housekeeping.jpg'
import amenities4 from '../../../../assets/roomAmenities/HotelRoomAmenities.webp'
import amenities5 from '../../../../assets/roomAmenities/RoomAmenities.jpg'
import amenities6 from '../../../../assets/roomAmenities/housekeeping.jpg'
import amenities7 from '../../../../assets/roomAmenities/HotelRoomAmenities.webp'
import amenities8 from '../../../../assets/roomAmenities/RoomAmenities.jpg'

const RoomAmenities = () => {
  return (
    <div className='category-list'>
      <h2 className='category-list-h2'>
        Sample of Room Amenities items here...
      </h2>
      <div className='list'>
        <img className='list-img' src={amenities1} alt='' />
        <img className='list-img' src={amenities2} alt='' />
        <img className='list-img' src={amenities3} alt='' />
        <img className='list-img' src={amenities4} alt='' />
        <img className='list-img' src={amenities5} alt='' />
        <img className='list-img' src={amenities6} alt='' />
        <img className='list-img' src={amenities7} alt='' />
        <img className='list-img' src={amenities8} alt='' />
      </div>
    </div>
  )
}

export default RoomAmenities
