import '../list.css'
import packaging1 from '../../../../assets/packaging/BagassePackaging.jpg'
import packaging2 from '../../../../assets/packaging/FoodPackagingMaterial.webp'
import packaging3 from '../../../../assets/packaging/PaperPackaging.jpg'
import packaging4 from '../../../../assets/packaging/foodpack.jpg'
import packaging5 from '../../../../assets/packaging/plasticPackaging.jpg'
import packaging6 from '../../../../assets/packaging/BagassePackaging.jpg'
import packaging7 from '../../../../assets/packaging/foodpack.jpg'
import packaging8 from '../../../../assets/packaging/plasticPackaging.jpg'

const Packaging = () => {
  return (
    <div className='category-list'>
      <h2 className='category-list-h2'>
        Sample of Packaging design or ideas here...
      </h2>
      <div className='list'>
        <img className='list-img' src={packaging1} alt='' />
        <img className='list-img' src={packaging2} alt='' />
        <img className='list-img' src={packaging3} alt='' />
        <img className='list-img' src={packaging4} alt='' />
        <img className='list-img' src={packaging5} alt='' />
        <img className='list-img' src={packaging6} alt='' />
        <img className='list-img' src={packaging7} alt='' />
        <img className='list-img' src={packaging8} alt='' />
      </div>
    </div>
  )
}

export default Packaging
