import React from 'react';
import './blog.css';

const Blog = () => {
  return (
    <div className='blog'>
      Blog
    </div>
  )
}

export default Blog;
