import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Nav from './components/nav/Nav';
import Homes from './components/pages/home/Homes';
import Category from './components/pages/category/Category';
import Shop from './components/pages/shop/Shop';
import About from './components/pages/about/About';
import Contact from './components/pages/contact/Contact';
import Blog from './components/pages/blog/Blog';
import Login from './components/pages/login/Login';
import Footer from './components/footer/Footer';
import Kitchen from './components/pages/categoryList/kitchen/Kitchen';
import Packaging from './components/pages/categoryList/packaging/Packaging';
import Crockery from './components/pages/categoryList/crockery/Crockery';
import Cutlery from './components/pages/categoryList/cutlery/Cutlery';
import RoomAmenities from './components/pages/categoryList/roomAmenities/RoomAmenities';
import Glassware from './components/pages/categoryList/glassware/Glassware';
function App() {
  return (
    <BrowserRouter>
        <Nav />
        <hr />
        <Routes>
          <Route path='/' element={<Homes/>}/>
          <Route path='/shop' element={<Shop />} />
          <Route path='/category' element={<Category />} />
          <Route path='/category/kitchen' element={<Kitchen />} />
          <Route path='/category/packaging' element={<Packaging />} />
          <Route path='/category/crockery' element={<Crockery />} />
          <Route path='/category/cutlery' element={<Cutlery />} />
          <Route path='/category/glassware' element={<Glassware />} />
          <Route path='/category/roomAmenities' element={<RoomAmenities />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/login' element={<Login />} />
        </Routes>
        <Footer />
      </BrowserRouter>
  );
}

export default App;
