import React from 'react';
import './category.css';
import kitchenpic from '../../../assets/kitchen/Kitchen-equipment.webp';
import foodpic from '../../../assets/packaging/FoodPackagingMaterial.webp';
import glasspic from '../../../assets/glassware/GlasswareAndBarware.webp';
import cutlery from '../../../assets/cutlery/cutlery1.webp';
import crockery from '../../../assets/crockery/Crockery.webp';
import hotelpic from '../../../assets/roomAmenities/HotelRoomAmenities.webp';
import { useNavigate } from 'react-router-dom';

const Category = () => {
    const navigate=useNavigate();
    const handleKitchen=()=>{
        navigate('/category/kitchen');
    }
    const handlePackaging=()=>{
        navigate('/category/packaging');
    }
    const handleCrockery=()=>{
        navigate('/category/crockery');
    }
    const handleCutlery=()=>{
        navigate('/category/cutlery');
    }
    const handleGlassware=()=>{
        navigate('/category/glassware');
    }
    const handleRoomAmenities=()=>{
        navigate('/category/roomAmenities');
    }
  return (
    <div className='category'>
        <div className="category-up">
            <div className="items">
                <img className='category-img' src={kitchenpic} alt="" />
                <div className="details">
                    <h3 className='category-h3'>Kitchen Equipment</h3>
                    <button className='category-btn' onClick={handleKitchen}>View</button>
                </div>
            </div>
            <div className="items">
                <img className='category-img' src={foodpic} alt="" />
                <div className="details">
                    <h3 className='category-h3'>Food Packaging</h3>
                    <button className='category-btn' onClick={handlePackaging}>View</button>
                </div>
            </div>
            <div className="items">
                <img className='category-img' src={crockery} alt="" />
                <div className="details">
                    <h3 className='category-h3'>Crockery</h3>
                    <button className='category-btn' onClick={handleCrockery}>View</button>
                </div>
            </div>
            <div className="items">
                <img className='category-img' src={cutlery} alt="" />
                <div className="details">
                    <h3 className='category-h3'>Cutlery</h3>
                    <button className='category-btn' onClick={handleCutlery}>View</button>
                </div>
            </div>
        </div>
        <div className="category-down">
            <div className="items">
                <img className='category-img' src={glasspic} alt="" />
                <div className="details">
                    <h3 className='category-h3'>Glassware & Barware</h3>
                    <button className='category-btn' onClick={handleGlassware}>View</button>
                </div>
            </div>
            <div className="items">
                <img className='category-img' src={hotelpic} alt="" />
                <div className="details">
                    <h3 className='category-h3'>Hotel Room & Amenities</h3>
                    <button className='category-btn' onClick={handleRoomAmenities}>View</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Category;
