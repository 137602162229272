import './nav.css'
import { NavLink } from 'react-router-dom'
import personIcon from '../../assets/icon/person-logo2.png'
import whatsappIcon from '../../assets/icon/whatsapp-icn.png'
import { useState } from 'react'

const Nav = () => {
  const [clicked, setClicked] = useState(false)
  const [menu, setMenu] = useState('home')

  return (
    <div id='nav' className={clicked ? '#nav active' : '#nav'}>
      <div className='nav-left'>
        <h3 className='nav-h3'>Essify</h3>
        <img
          className='nav-left-img'
          src='https://img.icons8.com/?size=100&id=78599&format=png&color=F5CF29'
          alt='logo'
        />
      </div>
      <div className='nav-right'>
        <div className='menu'>
          <ul className='nav-ul'>
            <li onClick={()=>setMenu("home")} className={menu === 'home' ? 'nav-li nav-home' : 'nav-li'}>
              <NavLink
                onClick={() => setClicked(false)}
                style={{ textDecoration: 'none' }}
                to='/'
              >
                HOME
              </NavLink>
            </li>
            <li onClick={()=>setMenu("category")} className={menu === 'category' ? 'nav-li nav-category' : 'nav-li'}>
              <NavLink
                className='nav-link'
                onClick={() => setClicked(false)}
                style={{ textDecoration: 'none' }}
                to='/category'
              >
                CATEGORIES
              </NavLink>
            </li>
            <li onClick={()=>setMenu("about")} className={menu === 'about' ? 'nav-li nav-about' : 'nav-li'}>
              <NavLink
                className='nav-link'
                onClick={() => setClicked(false)}
                style={{ textDecoration: 'none' }}
                to='/about'
              >
                ABOUT US
              </NavLink>
            </li>
            <li onClick={()=>setMenu("contact")} className={menu === 'contact' ? 'nav-li nav-contact' : 'nav-li'}>
              <NavLink
                className='nav-link'
                onClick={() => setClicked(false)}
                style={{ textDecoration: 'none' }}
                to='/contact'
              >
                CONTACT US
              </NavLink>
            </li>
            <li onClick={()=>setMenu("blog")} className={menu === 'blog' ? 'nav-li nav-blog' : 'nav-li'}>
              <NavLink
                className='nav-link'
                onClick={() => setClicked(false)}
                style={{ textDecoration: 'none' }}
                to='https://glazingstar.com/'
              >
                BLOG
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='nav-login'>
          <img src={personIcon} alt='' />
          <a href='/login'>Log In</a>
        </div>
        <div className='nav-chat-mobile'>
          <div className='nav-chat'>
            <img src={whatsappIcon} alt='' />
            <a href='https://wa.me/message/ZHHLJV5F26SBP1'>Let's Chat</a>
          </div>
          <div id='mobile' onClick={() => setClicked(!clicked)}>
            <i
              id='bar'
              className={clicked ? 'fas fa-times' : 'fas fa-bars'}
            ></i>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nav
