import React from 'react';
import './shop.css';

const Shop = () => {
  return (
    <div className='shop'>
      Shop
    </div>
  )
}

export default Shop
