import '../list.css'
import crockery1 from '../../../../assets/crockery/AcrylicCrockery.jpg'
import crockery2 from '../../../../assets/crockery/BonechinaCrockery.jpg'
import crockery3 from '../../../../assets/crockery/CeramicCrockery.jpg'
import crockery4 from '../../../../assets/crockery/Crockery.webp'
import crockery5 from '../../../../assets/crockery/AcrylicCrockery.jpg'
import crockery6 from '../../../../assets/crockery/BonechinaCrockery.jpg'
import crockery7 from '../../../../assets/crockery/CeramicCrockery.jpg'
import crockery8 from '../../../../assets/crockery/Crockery.webp'

const Crockery = () => {
  return (
    <div className='category-list'>
      <h2 className='category-list-h2'>Sample of Crockery items here...</h2>
      <div className='list'>
        <img className='list-img' src={crockery1} alt='' />
        <img className='list-img' src={crockery2} alt='' />
        <img className='list-img' src={crockery3} alt='' />
        <img className='list-img' src={crockery4} alt='' />
        <img className='list-img' src={crockery5} alt='' />
        <img className='list-img' src={crockery6} alt='' />
        <img className='list-img' src={crockery7} alt='' />
        <img className='list-img' src={crockery8} alt='' />
      </div>
    </div>
  )
}

export default Crockery
