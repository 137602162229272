import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className='about'>
      
    </div>
  )
}

export default About;
