import React from 'react';
import './login.css';

const Login = () => {
  return (
    <div className='login'>
      Login
    </div>
  )
}

export default Login
