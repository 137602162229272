import React from 'react';
import './contact.css';

const Contact = () => {
  return (
    <div className='contact'>
      <h3 className='contact-h3'>Consult our experts for your requirements.</h3>
      <div className="contact-form">
        <p className='contact-p1'>Drop us a message and we'll get back to you soon!</p>
        <form action="">
            <input type="text" placeholder='Enter name here' />
            <input type="email" placeholder='Email' required/>
            <input type="phone" placeholder='Phone' />
            <input type="text" placeholder='Your Company' />
            <textarea name="message" id="message" placeholder='Type message here...'></textarea>
            <button className='contact-btn'>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default Contact;
