import React from 'react'
import './home.css';
import backgroundImg from '../../../assets/homeback.jpg';
import homeImg from '../../../assets/homesecondImg.jpg';
import Marquee from 'react-fast-marquee';
import item1 from '../../../assets/brands/avanta.webp';
import item2 from '../../../assets/brands/awesomePalace.webp';
import item3 from '../../../assets/brands/bajoFood.webp';
import item4 from '../../../assets/brands/burgerSingh.webp';
import item5 from '../../../assets/brands/carnival.webp';
import item6 from '../../../assets/brands/cineport.webp';
import item7 from '../../../assets/brands/foodHanger.webp';
import item8 from '../../../assets/brands/maxims.webp';
import item9 from '../../../assets/brands/meridien.webp';
import item10 from '../../../assets/brands/miraj.webp';
import item11 from '../../../assets/brands/wowMomo.webp';
import { useNavigate } from 'react-router-dom';

const Homes = () => {

  const navigate=useNavigate();
    const handleKitchen=()=>{
        navigate('/category/kitchen');
    }
    const handlePackaging=()=>{
        navigate('/category/packaging');
    }
    const handleCrockery=()=>{
        navigate('/category/crockery');
    }
    const handleCutlery=()=>{
        navigate('/category/cutlery');
    }
    const handleGlassware=()=>{
        navigate('/category/glassware');
    }
    const handleRoomAmenities=()=>{
        navigate('/category/roomAmenities');
    }
  return (
    <div className='home'>
      <section className='first-section'>
        <h2 className='first-section-h2'>India's Leading Hospitality Procurement & Staffing Platform</h2>
        <div className="first-section-background">
          <img className='first-section-img' src={backgroundImg} alt="" />
          <h3 className='first-section-h3'>Hotel Procurement, Simplified</h3>
        </div>
      </section>
      <hr />
      <section className='image-section'>
        <img className='image-section-img' src={homeImg} alt="" />
      </section>
      <hr />
      <section className='second-section'>
        <h1 className='second-section-h1'>Trusted By more than 100 Brands across INDIA </h1>
        <div className="second-section-scroll">
            <Marquee pauseOnHover speed={100}>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item1} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item2} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item3} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item4} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item5} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item6} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item7} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item8} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item9} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item10} alt="" />
              </div>
              <div className='second-section-marquee-img'>
                <img className='second-section-img' src={item11} alt="" />
              </div>
            </Marquee>
        </div>
      </section>
      <section className='third-section'>
        <h1 className='third-section-h1'>Our Product Category</h1>
        <div className="third-section-cards">
          <div className="third-section-items" onClick={handleCutlery}>
            <img className='third-section-img' src="https://img.icons8.com/?size=80&id=AutEg4qGzuaO&format=png" alt="" />
            <p className='third-section-p1'>Cutlery</p>
          </div>       
          <div className="third-section-items" onClick={handleCrockery}>
            <img className='third-section-img' src="https://img.icons8.com/?size=100&id=9497&format=png&color=FA5252" alt="" />
            <p className='third-section-p1'>Crockery</p>
          </div>       
          <div className="third-section-items" onClick={handleGlassware}>
            <img className='third-section-img' src="https://img.icons8.com/?size=100&id=uy0gcBTx9NkZ&format=png&color=FA5252" alt="" />
            <p className='third-section-p1'>Glassware</p>
          </div>       
          <div className="third-section-items">
            <img className='third-section-img' src="https://img.icons8.com/?size=100&id=10214&format=png&color=FA5252" alt="" />
            <p className='third-section-p1'>Housekeeping</p>
          </div>       
          <div className="third-section-items" onClick={handleRoomAmenities}>
            <img className='third-section-img' src="https://img.icons8.com/?size=100&id=67274&format=png&color=FA5252" alt="" />
            <p className='third-section-p1'>Room Amenities</p>
          </div>       
          <div className="third-section-items" onClick={handlePackaging}>
            <img className='third-section-img' src="https://img.icons8.com/?size=100&id=sv4jxkTtS0tm&format=png&color=FA5252" alt="" />
            <p className='third-section-p1'>Packaging</p>
          </div>       
          <div className="third-section-items" onClick={handleKitchen}>
            <img className='third-section-img' src="https://img.icons8.com/?size=100&id=8472&format=png&color=FA5252" alt="" />
            <p className='third-section-p1'>Equipments</p>
          </div>       
          <div className="third-section-items">
            <img className='third-section-img' src="https://img.icons8.com/?size=100&id=8472&format=png&color=FA5252" alt="" />
            <p className='third-section-p1'>Uniforms</p>
          </div>       
        </div>
      </section>
    </div>
  )
}

export default Homes
